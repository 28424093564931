var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ct-web-chotot-id@2.0.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/storage/CT_WEB_CHOTOT_ID/87d7c871";

import * as Sentry from '@sentry/nextjs';
import { env } from './src/config';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || '0.1'),
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  environment: env,
  ignoreErrors: [/dataGetFacebookPixel/],
});
