export const checkIsMobile = (userAgent) => {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      userAgent
    )
  ) {
    return true;
  }
  return false;
};

export const formatOTPTimer = (distance) => {
  if (distance < 0) return '00:00';

  let result = '';
  // Time calculations for minutes and seconds
  const minutes = Math.floor(distance / 60000);
  const seconds = Math.floor((distance % 60000) / 1000);

  // Return the result
  result = `${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  return result;
};

export const getIdentifiers = (profile) => {
  const identifiers = [];
  if (profile.phone) {
    identifiers.push('phone');
  }
  if (profile.facebook_id) {
    identifiers.push('facebook');
  }
  if (profile.google_id) {
    identifiers.push('google');
  }
  if (profile.zalo_id) {
    identifiers.push('zalo');
  }
  if (profile.apple_id) {
    identifiers.push('apple');
  }

  const strIdentifiers = identifiers.toString();
  return strIdentifiers.replace(/,/g, ' | ');
};

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const rules = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
  // old chrome android webview agent
  'Linux; U; Android',
];
const webviewRegExp = new RegExp(`(${rules.join('|')})`, 'ig');

export const checkIfWebview = () => {
  const useragent = navigator.userAgent;
  return !!useragent.match(webviewRegExp);
};
