const ROUTE_NAME = {
  LIST_VIEW_HAVE_SUB_REGION: '#listViewHaveSubRegion',
  LIST_VIEW_REGION: '#listViewRegion',
  HOME: '#homeView',
  DETAIL_VIEW_SHORL_URL: '#detailViewShortUrl',
  DETAIL_VIEW_FULL_URL: '#detailViewFullUrl',
};

const PAGE_NAME = {
  LIST_VIEW: 'listView',
  DETAIL_VIEW: 'detailView',
  HOME: 'index',
  ERROR: 'error',
};

const PATTERN_URL = {
  LIST_VIEW_HAVE_SUB_REGION: '/:region/:subRegion/:category/:rest*',
  LIST_VIEW_REGION: '/:region/:category/:rest*',
  HOME: '/',
  DETAIL_VIEW_SHORT_URL: '/:listId.htm',
  DETAIL_VIEW_FULL_URL: '/:region/:subRegion/:category/:listId.htm',
};

// pls add to route params if you add more param on Pattern url
const ROUTE_PARAMS = ['region', 'subRegion', 'category', 'rest', 'listId'];

const exports = { PAGE_NAME, ROUTE_NAME, PATTERN_URL, ROUTE_PARAMS };
export default exports;
